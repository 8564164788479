<template>
  <div id="chart" class="d-flex justify-content-center"/>
</template>

<script>
import ApexCharts from 'apexcharts'
import { mapGetters } from 'vuex'
import { map } from 'lodash'
import moment from 'moment'

const namespaceStore = 'organizzeRelatoriosContas'

export default {
  name: 'Graph',

  props: {},
  directives: {},
  components: {},

  data: () => ({
    element: null,
    options:{
      theme: {
        mode: 'dark',
        monochrome: { enabled: false }
      },
      colors:['#419ef7', '#f35b5b', '#47ca82'],
      noData: { text: 'Carregando...' },
      series: [
        {
          name: 'Entradas',
          type: 'column',
          data: [],
        },{
          name: 'Saldo',
          type: 'line',
          data: []
        },{
          name: 'Despesas',
          type: 'column',
          data: []
        }
      ],
      fill: { type: "gradient" },
      chart: { height: 400 },
      stroke: { curve: 'smooth' },
      dataLabels: {
        style: {
          // colors: ['#F44336', '#E91E63']
        }
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: '50%',
        }
      },
      grid: {
        row: {
          // colors: ['#fff', '#f2f2f2']
        },
        column: {
          // colors:  ['#F44336', '#E91E63']
        }
      },
      markers: {
        size: 5,
        radius: 2,
        colors: ['#419ef7', '#FF0000'],
        hover: {
          size: 10,
          sizeOffset: 3
        }
      },
      yaxis: {
        labels: {
          formatter: (value) => {
            return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value) || 0
          }
        }
      },
      xaxis: {
        categories: [],
        label: { formatter: (v) => v || '' }
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: (v) => {
            return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(v) || 0
          }
        }
      }
    }
  }),

  created () {},

  mounted () {
    const self = this

    self.element = new ApexCharts(document.querySelector("#chart"), self.options)
    self.element.render()
    self.updateSeries()
  },

  computed: {
    ...mapGetters(namespaceStore, ['result'])
  },

  watch: {
    'result' (val) { this.updateSeries(val) }
  },

  methods: {
    updateSeries () {
      const self = this

      if (self.result.length === 0) {
         self.element.updateOptions({
           noData: {
             text: 'Dados não encontrados.'
           }
         })
        return
      }

      self.element.updateOptions({
      series: [{
        name: 'Saldo',
        type: 'line',
        data: self.formatData().saldo
      },
      {
        name: 'Despesas',
        type: 'column',
        data: self.formatData().despesa
      },
      {
        name: 'Receitas',
        type: 'column',
        data: self.formatData().receita
      }],
      xaxis: {
        categories: self.formatData().labels
      }
      })
    },



    formatData () {
      const label = this.formatLabel
      let dados = [{
          name: 'Saldo',
          type: 'line',
          data: []
        },
        {
          name: 'Despesas',
          type: 'colunm',
          data: []
        },
        {

          type: 'colunm',
          data: []
      }]
      const result = this.result
      if (result.length === 0) {
        return dados
      }
      const saldo = map(result, v => (parseFloat(v.Records_balance).toFixed(2)))
      const receita = map(result, v => parseFloat(v.Records_income).toFixed(2))
      const despesa = map(result, v => parseFloat(v.Records_outcome).toFixed(2))
      const labels = map(result, v => {
        if (label === 'day') {
          return moment(v.Records_Date).format('DD/MM/YYYY')
        }
        if (label === 'week') {
          let format = moment().weeks(v.Records_Week).year(v.Records_Year).format('DD/MM/YYYY')
          return format
        }
        return `${v.Records_Month}/${v.Records_Year}`
      })

      return { saldo, receita, despesa, labels }
    }
  },

  validations () { return {} }
}
</script>